@import "~/frontend/scss/stage0";

$primary: #ffd800;
$secondary: #303338;
$enable-gradients: true;
$gradient: linear-gradient(180deg, rgba(#000, 0), rgba(#000, 0.2)) !default;

@import "~/frontend/scss/stage1";

// dropdown
$dropdown-dark-bg: $secondary;
$dropdown-dark-link-color: $light;
// navbar
$navbar-light-toggler-border-color: $light;
$navbar-dark-toggler-border-color: $dark;
$navbar-light-color: $secondary;
$navbar-light-hover-color: darken($secondary, 70%);
$navbar-light-active-color: darken($secondary, 90%);
$navbar-light-disabled-color: darken($secondary, 30%);

@import "~/frontend/scss/stage2";
